/* You can add global styles to this file, and also import other style files */

html {
   height: 100%;
}

body {
   height: 100%;
   margin: 0;
   font-family: "Open Sans", sans-serif !important;
}

/*
    ******************************************************************
    Global style changes to make mat-slider's thumbs to always display
    ******************************************************************
*/
.mat-slider-thumb-label {
   transform: rotate(45deg) !important;
   border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
   transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
   opacity: 1 !important;
}

.mat-tooltip {
   font-size: 11px;
}

.alert-danger {
   color: #721c24;
   background-color: #f8d7da;
   border-color: #f5c6cb;
   margin-top: 10px;
   margin-bottom: 10px;
   padding: 10px;
   border-radius: 5px;
}

h1 {
   font-family: "Open Sans", sans-serif !important;
   font-size: 32px !important;
}

* {
   font-family: "Open Sans", sans-serif !important;
}

.mat-typography {
   font-family: "Open Sans", sans-serif !important;
   font-size: 14px !important;
   line-height: 1.42857143 !important;
   color: #333 !important;
   background-color: #ebebeb !important;
}

.mat-typography h1 {
   font-size: 32px;
   font-family: "Open Sans", sans-serif !important;
}
.mat-typography h2, h2, h3, h4, h5 {
   font-family: "Open Sans", sans-serif !important;
}
.form-group {
   font-family: "Open Sans", sans-serif !important;
}

div {
   &.component-container {
      padding: 3rem 4rem;
   }
}

/*
    ******************************************************************
    Global style changes to remove Mat Dialog's extra padding space
    ******************************************************************
*/
.survey-dialog {
   .mat-dialog-container {
      padding: 0;
   }
}

div {
   &.component-container {
      padding: 3rem 4rem;
   }

   &.content-box {
      $border-color: #ccc;
      $box-padding: 0.5rem;

      width: calc(100% - 2 * #{$box-padding});
      height: 100%;
      padding: 0 $box-padding 0 $box-padding;
      display: flex;
      flex-flow: column;

      div.content-box-header {
         padding: .75rem;
         border-width: 1px 1px 0 1px;
         border-style: solid;
         border-color: $border-color;
         flex: 0 1 auto;

         .title {
            font-size: 1rem;
         }
      }

      div.content-box-body {
         overflow: auto;
         padding: .75rem;
         font-size: .95rem;
         border: 1px solid $border-color;
         flex: 1 1 auto;

         input {
            font-size: .95rem;
         }
      }

      div.content-box-footer {
         padding: .75rem;
         border-width: 0 1px 1px 1px;
         border-style: solid;
         border-color: $border-color;
         text-align: right;
         flex: 0 1 auto;
      }
   }
}

.medium-bold {
   font-weight: 600;
}
